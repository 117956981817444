import * as React from 'react'
import WebPresence from "../components/web-presence";
import {Link} from "gatsby";
import Template from "../components/template";
import "./index.css";
import {StaticImage} from "gatsby-plugin-image";

class Page extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    onMoreClick = () => {
        this.setState({expanded: true});
    }

    render() {
        const years = new Date(new Date() - new Date('2000-01-01T00:00:00Z')).getUTCFullYear() - 1970;
        const age = new Date(new Date() - new Date('1984-08-30T03:00:00Z')).getUTCFullYear() - 1970;
        const skillsClass = "skills" + (this.state.expanded ? " expanded" : "");
        return (
            <Template>
                <div className="block flex">
                    <div className="pr-4 sm:text-xl flex-grow space-y-2">
                        <p>Hello!</p>
                        <p className="font-bold">I'm Alexander Kharitonov,<br/>VP of Engineering @ <a className="whitespace-nowrap" href="https://coinmetrics.io" rel="nofollow" target="_blank">Coin Metrics</a>.</p>
                        <p>I like to make the best things <span className="whitespace-nowrap">in the world <img src={`static/i/rocket.svg`} className="w-5 inline" alt="rocket"/></span></p>
                    </div>
                    <div className="avatar">
                        <div className="rounded-btn">
                            <StaticImage src="../images/me.png" alt="Me"/>
                        </div>
                    </div>
                </div>
                <div className="block space-y-2">
                    <h2 className="text-xl">About me</h2>
                    <p>Java/Kotlin high-performance enthusiast {age} y.o. currently based in <span className="whitespace-nowrap"><img className="inline w-5" src={`/static/i/place.svg`} alt="place"/> <span className="font-bold">Boston, US</span></span>.</p>
                    <p>Deep knowledge in high-performance Java/Kotlin, distributed, and Big Data systems architecture.</p>
                    <p>I like to find the most effective and simple solutions to complex problems and deliver results as soon as possible.</p>
                    <p>Experience in developing popular and highly loaded startups from scratch for over {years} years.</p>
                    <p>Acted as a Lead Software Engineer, Team Lead, Founder, or sole full-stack developer in most projects.</p>
                    <p>I know how to build complex but reliable systems and do this currently.</p>
                    <p>For more, see <Link to="/projects/">my projects</Link>, <Link to="/achievements/">achievements</Link>, <Link to="/media/">media & publications</Link>.</p>
                </div>
                <div className="block space-y-2">
                    <h2 className="text-xl">Top Interests & Skills</h2>
                    <ul className={skillsClass}>
                        <li>Kotlin/Java</li>
                        <li>Startups</li>
                        <li>System architecture</li>
                        <li>Performance optimization</li>
                        <li>Distributed systems</li>
                        <li>Reliability</li>
                        <li>Troubleshooting</li>
                        <li>Linux</li>
                        <li>Full-stack</li>
                        <li>Team Leading</li>
                        <li>Founder</li>
                        <li className="show-extra-skills" onClick={this.onMoreClick}>more...</li>
                        <li className="extra-skill">Painting</li>
                        <li className="extra-skill">Science</li>
                        <li className="extra-skill">Research</li>
                    </ul>
                </div>

                <div className="block space-y-2">
                    <p className="text-xl">Follow me at</p>
                    <WebPresence/>
                </div>
            </Template>
        )
    }
}
export default Page
