import React from "react"
import * as containerStyles from "./web-presence.module.css"
export default function WebPresence() {
    return <p className={containerStyles.webPresence}>
        <a href="https://www.facebook.com/chemist777" target="_blank" title="Facebook" rel="nofollow">
            <img src={`/static/i/facebook.svg`} alt="Facebook"/>
        </a>
        <a href="https://linkedin.com/in/chemist777/" target="_blank" title="LinkedIn" rel="nofollow">
            <img src={`/static/i/linkedin.svg`} alt="LinkedIn"/>
        </a>
        <a href="https://www.instagram.com/chemist789/" target="_blank" title="Instagram" rel="nofollow">
            <img src={`/static/i/instagram.svg`} alt="Instagram"/>
        </a>
        <a href="https://github.com/chemist777" target="_blank" title="GitHub" rel="nofollow">
            <img src={`/static/i/github.svg`} alt="GitHub"/>
        </a>
        <a href="https://gitlab.com/chemist777" target="_blank" title="GitLab" rel="nofollow">
            <img src={`/static/i/gitlab.svg`} alt="GitLab"/>
        </a>
        <a href="https://www.youtube.com/channel/UCzB6c30QhbHOLK6A8mVsXDw" target="_blank" title="YouTube"
           rel="nofollow">
            <img src={`/static/i/youtube.svg`} alt="YouTube"/>
        </a>
        <a href="https://twitter.com/chemist777" target="_blank" title="Twitter" rel="nofollow">
            <img src={`/static/i/twitter.svg`} alt="Twitter"/>
        </a>
    </p>
}
